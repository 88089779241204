import type { AbstractGroup, SectionParams, TeaserFragment } from '@hubcms/domain-cook';
import type { TStoryblock } from '@hubcms/domain-storyblock';
import { type TeaserPropsOptions, isTeaserList, type TeaserDataOrList } from '@hubcms/domain-teaser';
import { isNonNull } from '@hubcms/utils-browser';

import { createTeaserAreas } from './createTeaserAreas';
import { getStoryblockOptions } from './getStoryblockOptions';
import { mapStoryblock } from './mapStoryblock';
import { mapToGroupOptionsRecord } from './mapToGroupOptionsRecord';

type UseStoryblockOptions = {
  areaNames?: string[];
  hasNoAds?: boolean;
  sectionParams: SectionParams;
  teaserPropsOptions: TeaserPropsOptions;
  position: 'main' | 'aside' | 'bottom';
};

type Group = AbstractGroup & Record<string, AbstractGroup[] | TeaserFragment[]>;

function getIsFirstFilledGroup(position: UseStoryblockOptions['position']) {
  let firstFilledGroupIndex: null | number = null;

  if (position !== 'main') {
    return () => false;
  }

  return (unflattenedTeaserAreas: Record<string, TeaserDataOrList[]>, idx: number) => {
    const filledGroupIndex = Object.values(unflattenedTeaserAreas).findIndex(group => group.length > 0);

    if (filledGroupIndex !== -1 && firstFilledGroupIndex === null) {
      firstFilledGroupIndex = idx;
    }

    return firstFilledGroupIndex === idx;
  };
}

export function getStoryblocks(
  rootGroup: Group | undefined,
  { areaNames = rootGroup?.areaNames || [], hasNoAds = false, sectionParams, teaserPropsOptions, position }: UseStoryblockOptions,
): TStoryblock[] {
  if (!rootGroup) {
    return [];
  }

  const createStoryblockFromGroup = storyblockCreator(teaserPropsOptions, {
    sectionParams,
    hasNoAds,
  });

  const isFirstFilledGroup = getIsFirstFilledGroup(position);

  const storyblocks: TStoryblock[] = areaNames
    .filter(areaName => isValidAreaName(areaName, rootGroup))
    .flatMap((areaName, idx) => {
      const area = rootGroup[areaName];

      if (isAreaWithoutGroups(area)) {
        const unflattenedTeaserAreas = createTeaserAreas(rootGroup, teaserPropsOptions, areaName);

        const storyblock = createStoryblockFromGroup(rootGroup, 0, {
          name: areaName,
          isImagePriority: isFirstFilledGroup(unflattenedTeaserAreas, 0),
          unflattenedTeaserAreas,
        });
        return [storyblock];
      }

      return area
        .filter(
          ({ groupOptions }) => groupOptions && mapToGroupOptionsRecord(groupOptions).webStoryblock?.toLowerCase() !== 'hide',
        )
        .map((group, idx) => {
          const unflattenedTeaserAreas = createTeaserAreas(group, teaserPropsOptions);

          return createStoryblockFromGroup(group, idx, {
            name: areaName,
            isImagePriority: isFirstFilledGroup(unflattenedTeaserAreas, idx),
            unflattenedTeaserAreas,
          });
        });
    })
    .filter(filterEmptyStoryblocks);

  return storyblocks;
}

function isValidAreaName(areaName: string, group: Group) {
  return !areaName.startsWith('newsflow') && areaName in group && isNonNull(group[areaName]);
}

function isAreaWithoutGroups(area: AbstractGroup[] | TeaserFragment[]): area is TeaserFragment[] {
  return !!area.length && !('groupOptions' in area[0]);
}

type StoryblockCreatorOptions = {
  sectionParams: SectionParams;
  hasNoAds: boolean;
};
function storyblockCreator(teaserPropsOptions: TeaserPropsOptions, { sectionParams, hasNoAds }: StoryblockCreatorOptions) {
  return function createStoryblockFromGroup(
    group: AbstractGroup,
    idx: number,
    area: {
      name: string;
      unflattenedTeaserAreas: Record<string, TeaserDataOrList[]>;
      isImagePriority: boolean;
    },
  ): TStoryblock {
    const storyblockOptions = getStoryblockOptions({ group, sectionParams, canHaveAds: !hasNoAds });
    const groupTitle = group.groupOptions.find(({ key }) => key === 'groupTitle')?.value?.replace(/ /g, '-');

    return {
      areaName: area.name,
      blockName: `${group.type}_${idx}`,
      storyblockOptions,
      theme: getTheme(group),
      unflattenedTeaserAreas: area.unflattenedTeaserAreas,
      groupId: [area.name, group.__typename, groupTitle, idx].filter(item => !!item).join('-'),
      createGridData: (teaserAreas, overrideStoryblockOptions) =>
        mapStoryblock({ ...storyblockOptions, ...overrideStoryblockOptions, isImagePriority: area.isImagePriority }, teaserAreas),
    };
  };
}

function filterEmptyStoryblocks(storyblock: TStoryblock): boolean {
  return Object.values(storyblock.unflattenedTeaserAreas).some(area => {
    // check for teasers
    return (
      area.length > 0 &&
      area.some(teaser => !isTeaserList(teaser) || teaser.listType !== 'article-list' || teaser.items.length > 0)
    );
  });
}

function getTheme(group: AbstractGroup): string {
  const groupOptions = mapToGroupOptionsRecord(group.groupOptions);

  return groupOptions.groupTheme || 'none';
}
