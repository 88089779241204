import type { CustomizableGroupOptions } from '@hubcms/domain-storyblock';
import {
  type ArticleTeaserData,
  type HtmlTeaserData,
  type NewsletterTeaserData,
  type PlaceholderTeaserData,
  type TeaserData,
  isArticleTeaserData,
} from '@hubcms/domain-teaser';
import type { GGrid, GTitle, TTeaserGridItem } from '@hubcms/domain-teaser-grid';

import { createListData } from '../../../internal/createListData';
import { createListItems } from '../../../internal/createListItems';
import { hideGridItemOnMobile } from '../../../internal/hideGridItemOnMobile';
import { isCustomizableStoryblockTeaserData } from '../../../internal/isCustomizableStoryblockTeaserData';
import { createFullAreaAd } from '../../createFullAreaAd';

import { areaThreeUtils } from './areaThreeUtils';
import { createAreaWithCbaAds } from './createAreaWithCbaAds';
import { createCustomizableStoryblockTeaser } from './createCustomizableStoryblockTeaser';
import type { BaseParams } from './types';

type AreaThreeOptions = CustomizableGroupOptions['areaThreeOptions'];

export function mapToAreaThreeItems(
  teaserDataList: TeaserData[],
  areaThreeOptions: AreaThreeOptions,
  params: BaseParams & {
    areaThreeTitle: GTitle | null;
    isAreaTwoStackedMobile: boolean;
    isAreaOneWithIntro: boolean;
    isImagePriority: boolean;
  },
): Array<TTeaserGridItem<string, unknown>> {
  if (params.areaThreeTitle) {
    params.areaThreeTitle.data.size = 'sm';
    if (areaThreeOptions.isAreaHiddenMobile) {
      params.areaThreeTitle.gridProps = hideGridItemOnMobile(params.areaThreeTitle.gridProps);
    }
  }

  if (areaThreeOptions.isList && !areaThreeOptions.hasAd) {
    const listItems = createListItems(teaserDataList, areaThreeOptions);

    const lgBlockHeight = areaThreeUtils.calculateListHeight(params);
    const listArea = createListData(listItems, areaThreeOptions, lgBlockHeight);
    listArea.gridProps = {
      ...listArea.gridProps,
      column: 'span 2',
      columnLg: '-2',
    };

    const gridItems: Array<TTeaserGridItem<string, unknown>> = [listArea];

    listArea.data.title = params.areaThreeTitle;

    if (areaThreeOptions.isAreaHiddenMobile) {
      gridItems.forEach(gridItem => {
        gridItem.gridProps = hideGridItemOnMobile(gridItem.gridProps);
      });
    }
    return gridItems;
  }

  const isAreaOnePositionTop = params.areaOnePosition === 'top';
  const isOnlyItem = teaserDataList.length === 1;
  const gridItems =
    areaThreeOptions.hasAd || areaThreeOptions.hasCbaAd
      ? createAdItems(params, teaserDataList.filter(isCustomizableStoryblockTeaserData), areaThreeOptions)
      : teaserDataList
          .filter(isCustomizableStoryblockTeaserData)
          .slice(0, 2)
          .map((teaserData, teaserIdx, teaserArray) => {
            if (isArticleTeaserData(teaserData) && teaserData.trackingData) {
              teaserData.trackingData.clickitemposition = teaserIdx + 1;
            }

            const isLastInArea = teaserIdx === teaserArray.length - 1;
            const teaser = createCustomizableStoryblockTeaser(teaserData, areaThreeOptions, {
              isAreaOnePositionTop,
              isLastInArea,
              isOnlyItem,
              isPriority: params.isImagePriority,
            });

            if (teaser && !params.isAreaTwoStackedMobile && areaThreeOptions.isStackedMobile && teaserIdx === 0) {
              teaser.gridProps = {
                ...teaser.gridProps,
                hasInverseMarginBlockStartSm: false,
              };
            }

            return teaser;
          });

  let columnHeight = 2;
  if (isAreaOnePositionTop) {
    columnHeight++;
  }

  if (!params.areaThreeTitle) {
    const gridItemsInLastColumn = gridItems.map(areaThreeUtils.moveToLastColumn);
    if (!isAreaOnePositionTop || isOnlyItem) {
      return gridItemsInLastColumn;
    }
    return gridItemsInLastColumn.map(areaThreeUtils.spanLastItemOnTwoRows);
  }

  params.areaThreeTitle.gridProps = {
    ...params.areaThreeTitle.gridProps,
    hasInverseMarginBlockEndSm: !(areaThreeOptions.isHighlighted && areaThreeOptions.isStackedMobile),
    hasInverseMarginBlockEndMd: true,
    hasInverseMarginBlockEndLg: true,
    hasNoGridDivider: true,
  };

  if (params.hasGroupTitle) {
    const gridItemsInLastColumn = [params.areaThreeTitle, ...gridItems].map(areaThreeUtils.moveToLastColumn);
    if (!isAreaOnePositionTop || isOnlyItem) {
      return gridItemsInLastColumn;
    }
    return gridItemsInLastColumn.map(areaThreeUtils.spanLastItemOnTwoRows);
  }

  if (areaThreeOptions.isAreaHiddenMobile) {
    params.areaThreeTitle.gridProps = hideGridItemOnMobile(params.areaThreeTitle.gridProps);
  }

  const grid: GGrid = {
    type: 'grid',
    data: {
      title: params.areaThreeTitle,
      gridColumns: 2,
      gridColumnsMd: 2,
      gridColumnsLg: 1,
      hasInlinePadding: { xs: true, sm: true, md: true, lg: false, xl: false },
      items: gridItems,
      height: areaThreeOptions.hasAd ? '100%' : 'auto',
    },
    gridProps: {
      column: 'span 2',
      hasInverseMarginInlineSm: true,
      rowLg: `1 / span ${columnHeight}`,
    },
  };
  return [areaThreeUtils.moveToLastColumn(grid)];
}

function createAdItems(
  params: BaseParams,
  teaserData: Array<HtmlTeaserData | ArticleTeaserData | PlaceholderTeaserData | NewsletterTeaserData>,
  areaThreeOptions: AreaThreeOptions,
) {
  if (areaThreeOptions.hasCbaAd) {
    const cbaAreaItems = createAreaWithCbaAds(teaserData, areaThreeOptions, params.areaOnePosition === 'top');

    if (areaThreeOptions.isAreaHiddenMobile) {
      return cbaAreaItems.map(item => {
        item.gridProps = hideGridItemOnMobile(item.gridProps);
        return item;
      });
    }

    return cbaAreaItems;
  }

  return [createFullAreaAdWithGridProps(params, areaThreeOptions)];
}

function createFullAreaAdWithGridProps(params: BaseParams, areaThreeOptions: AreaThreeOptions) {
  const ad = createFullAreaAd(areaThreeOptions.fullAdFormat);
  ad.gridProps = {
    column: 'span 2',
    columnLg: 'auto',
    rowLg: areaThreeUtils.getAdRowLg(params),
    minWidth: 'min-content',
  };
  if (areaThreeOptions.isAreaHiddenMobile) {
    ad.gridProps = hideGridItemOnMobile(ad.gridProps);
  }
  return ad;
}
