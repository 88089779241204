import { storyblockTeaserSizesConfig } from '@hubcms/brand';
import type { VisualStoriesGroupOptions } from '@hubcms/domain-storyblock';
import { isHtmlTeaserData, isPlaceholderTeaserData } from '@hubcms/domain-teaser';
import { type GGrid, type GHtml, type GTextOnImageTeaser, createTitle } from '@hubcms/domain-teaser-grid';

import type { MapStoryblockFn } from '../../domain/map-storyblock-fn';
import { isHtmlOrArticleOrPlaceholderTeaserData } from '../../internal/isArticleOrPlaceholderTeaserData';
import { createHtmlGridItem } from '../createHtmlGridItem';
import { getGroupLink } from '../getGroupLink';
import { getTeaserSize } from '../getTeaserSize';

const getVisualStoryTeaserSizes = getTeaserSize(storyblockTeaserSizesConfig, 'visualstory');

export const mapVisualStoriesGroup: MapStoryblockFn<VisualStoriesGroupOptions> = (storyblockOptions, teaserAreas) => {
  const groupOptions = storyblockOptions.legacyGroupOptions;

  const numberOfTeasers = Number(groupOptions['webStoryblock'].split('-')?.[1]) as 4 | 8;
  const groupLink = getGroupLink(groupOptions, 'groupTagLink', 'groupSectionLink');
  const title = createTitle(groupOptions['groupTitle'], groupLink, storyblockOptions.actionText);

  const items: Array<GHtml | GTextOnImageTeaser> = teaserAreas.groupArea
    .filter(isHtmlOrArticleOrPlaceholderTeaserData)
    .slice(0, numberOfTeasers)
    .map((teaserData, idx) => {
      if (isHtmlTeaserData(teaserData)) {
        const gridItem = createHtmlGridItem(teaserData);
        gridItem.gridProps = {
          ...gridItem.gridProps,
          minWidth: { sm: '240px' },
        };
        return gridItem;
      }
      const isPlaceholder = isPlaceholderTeaserData(teaserData);
      return {
        type: 'text-on-image-teaser',
        data: {
          hasLabel: true,
          teaserData,
          imageFormat: 'fourFive',
          imageSizes: {
            sm: '240px',
            md: '480px',
            lg: '308px',
          },
          hasIntro: false,
          isPriority: storyblockOptions.isImagePriority,
          headerLevel: 3,
          hasDateTime: false,
          size: getVisualStoryTeaserSizes(false),
          trackingData: {
            ...(!isPlaceholder ? teaserData.trackingData : {}),
            clickitemblock: groupOptions['groupTitle'],
            clickitemposition: idx + 1,
          },
        },
        gridProps: {
          minWidth: { sm: '240px' },
        },
      };
    });

  return {
    key: 'groupArea',
    hasInlinePadding: { xs: true, sm: true, md: true, lg: false, xl: false },
    title,
    items: [createGridArea(items, numberOfTeasers)],
  };
};

function createGridArea(items: GGrid['data']['items'], numberOfTeasers: number): GGrid {
  return {
    type: 'grid',
    data: {
      items,
      gridColumns: numberOfTeasers,
      gridColumnsMd: 2,
      gridColumnsLg: 4,
      hasInlinePadding: false,
      scroll: {
        breakpoints: {
          xs: true,
          sm: true,
          md: false,
          lg: false,
          xl: false,
        },
      },
    },
    gridProps: {
      column: '1 / -1',
      columnLg: '1 / -1',
    },
  };
}
