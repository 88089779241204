import type { CustomizableGroupOptions } from '@hubcms/domain-storyblock';
import { type GTitle, type TGridProps, type TTeaserGrid, createTitle } from '@hubcms/domain-teaser-grid';

import type { MapStoryblockFn } from '../../../domain/map-storyblock-fn';

import { mapToAreaOneItems } from './mapToAreaOneItems';
import { mapToAreaThreeItems } from './mapToAreaThreeItems';
import { mapToAreaTwoItems } from './mapToAreaTwoItems';
import type { BaseParams } from './types';

export const mapToCustomizableStoryblockGroup: MapStoryblockFn<CustomizableGroupOptions> = (
  storyblockOptions,
  { areaOne, areaTwo, areaThree },
) => {
  const { areaOnePosition, areaOneOptions, areaTwoOptions, areaThreeOptions } = storyblockOptions;

  const title = createTitle(storyblockOptions.title, storyblockOptions.actionLink, storyblockOptions.actionText);
  const areaThreeTitle = createTitle(areaThreeOptions.title, areaThreeOptions.actionLink, areaThreeOptions.actionText);

  const baseParams: BaseParams = {
    areaOnePosition,
    hasGroupTitle: !!title,
    hasAreaThreeTitle: !!areaThreeTitle,
    isImagePriority: storyblockOptions.isImagePriority ?? false,
  };

  const areaOneItems = mapToAreaOneItems(areaOne, areaOneOptions, baseParams);

  const areaTwoItems = mapToAreaTwoItems(areaTwo, areaTwoOptions, {
    ...baseParams,
    isAreaThreeHiddenMobile: areaThreeOptions.isAreaHiddenMobile,
    isAreaThreeStackedMobile: areaThreeOptions.isStackedMobile,
    isAreaThreeList: areaThreeOptions.isList,
    isAreaThreeAnAd: areaThreeOptions.hasAd ?? false,
  });

  const areaThreeItems = mapToAreaThreeItems(areaThree, areaThreeOptions, {
    ...baseParams,
    areaThreeTitle,
    isAreaOneWithIntro: areaOneOptions.hasIntroDesktop,
    isAreaTwoStackedMobile: areaTwoOptions.isStackedMobile && !areaTwoOptions.isAreaHiddenMobile,
  });

  if (title && baseParams.hasAreaThreeTitle) {
    title.gridProps = positionGroupTitle(title);
  }

  const gridData: TTeaserGrid = {
    title,
    items: [...areaOneItems, ...areaTwoItems, ...areaThreeItems],
    gridAutoFlowLg: 'column',
    gridRowsLg: areaOnePosition === 'top' ? 'repeat(2, min-content) 1fr' : 'repeat(2, 1fr)',
    gridAutoRows: 'min-content',
    gridColumns: 2,
    gridColumnsMd: 2,
    gridColumnsLg: areaOnePosition === 'top' ? 3 : 4,
    hasInlinePadding: { xs: true, sm: true, md: true, lg: false, xl: false },
  };
  return gridData;
};

function positionGroupTitle(title: GTitle): TGridProps {
  return {
    ...title.gridProps,
    columnLg: '1 / -2',
  };
}
